import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
// import './App.scss';
// @use './App.scss';
import './webflow/css/webflow.css';
import './webflow/css/badman-skulls.css';
import logo from './webflow/images/bm_logo.png';

function App() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <>
    <header>
      <div class="site-container flex-center h-100">
        <ul class="icon-menu">
          <li>
            <a href="https://twitter.com/badmanskullgang" target="_blank">
              <i class="fab fa-twitter"></i>
              <span>Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/badmanskulls" target="_blank">
              <i class="fab fa-instagram"></i>
              <span>Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/badmanskullgang" target="_blank">
              <i class="fab fa-discord"></i>
              <span>Discord</span>
            </a>
          </li>
        </ul>
        <a href="https://mint1.badmanskulls.com" class="btn-1">Mint</a>
      </div>
    </header>
    <main>
        <div class="backdrop"></div>
        {/* <h1>Create React App + Go API</h1>
    <h2>
      Deployed with{' '}
      <a
        href="https://vercel.com/docs"
        target="_blank"
        rel="noreferrer noopener"
      >
        Vercel
      </a>
      !
    </h2>
    <p>
      <a
        href="https://github.com/vercel/vercel/tree/main/examples/create-react-app"
        target="_blank"
        rel="noreferrer noopener"
      >
        This project
      </a>{' '}
      was bootstrapped with{' '}
      <a href="https://facebook.github.io/create-react-app/">
        Create React App
      </a>{' '}
      and contains three directories, <code>/public</code> for static assets,{' '}
      <code>/src</code> for components and content, and <code>/api</code>{' '}
      which contains a serverless <a href="https://golang.org/">Go</a>{' '}
      function. See{' '}
      <a href="/api/date">
        <code>api/date</code> for the Date API with Go
      </a>
      .
    </p>
    <br />
    <h2>The date according to Go is:</h2>
    <p>{date ? date : 'Loading date...'}</p> */}
    </main>
    <footer>
      <div class="site-container flex-center h-100">
        <img src={logo} class="footer-logo" alt="BADMAN SKULLS" />
      </div>
    </footer>
    </>
  );
}

export default App;
